<template>
  <div id="app">
    <template v-if="isConfigSuccess">
      <component class="component" :is="isMobile ? 'MobileHome' : 'PcHome'" />
    </template>
    <iframe
      class="store-iframe-app"
      id="mainIframe"
      width="100%"
      scrolling="auto"
      frameborder="0"
      :src="iframeUrl"
    />
  </div>
</template>

<script>
import MobileHome from "@/views/mobileCom/MobileHome.vue";
import PcHome from "@/views/components/PcHome.vue";
import setRem from "@/utils/setRem";
import axios from "axios";
import "@/style/index.scss";
import {
  getInfo,
} from "@/api/platformCenter";
import * as dd from 'dingtalk-jsapi';
export default {
  name: "App",
  components: {
    MobileHome,
    PcHome,
  },
  data() {
    return {
      iframeUrl: `${process.env.VUE_APP_DEVELOPER_URL}/appstore/home`,
      isMobile: false,
      isConfigSuccess: false,
    };
  },
 async created() {
    // if (localStorage.getItem('ucenter_Authorization')) {
    //   sessionStorage.setItem('Authorization', localStorage.getItem('ucenter_Authorization'));
    //   sessionStorage.setItem('ucenter_Authorization', localStorage.getItem('ucenter_Authorization'));
    // }
    // let { data } = await axios.get("./static/config-info.json");
    const data = [
      { "hostName": "cloud.nti56.com", "clientId": "" },
      { "hostName": "iiot.szhlsn.cn", "clientId": "1010203040506070812" },
      { "hostName": "localhost", "clientId": "" }
    ]
    localStorage.setItem("configInfo", JSON.stringify(data));
    this.isConfigSuccess = true;

    document.querySelector("body").setAttribute("style", "font-size: 12px");
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    // var mobile_bs= this.mobile_bs();
    // if (mobile_bs.mobile) {
    //   if (
    //     mobile_bs.android ||
    //     mobile_bs.iPhone ||
    //     mobile_bs.iPad ||
    //     mobile_bs.ios
    //   ) {
    //     alert("这是移动端" + navigator.userAgent);
    //   }
    // }
    // console.log("----从钉钉登录", this.$route, location.href);

  },
  beforeUpdate() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  mounted() {
    let self = this;
    setRem();
    // 窗口变化后，重置rem
    window.addEventListener("resize", (e) => {
      self.$nextTick(() => {
        setRem();
      });
    });
    if (dd.env.platform !== 'notInDingTalk' && navigator.userAgent.indexOf('DingTalk') > -1) {
      // 钉钉PC端
      localStorage.setItem('equipType', 4);
    } else {
      localStorage.setItem('equipType', 1);
      if (localStorage.getItem('ucenter_Authorization')) {
        if (sessionStorage.getItem("ucenter_Authorization") === null) {
            // 登陆过且缓存没有失效，新的tab标签 从中打开 sessionStorage 要重新保存token
            sessionStorage.setItem('Authorization', localStorage.getItem('ucenter_Authorization'));
            sessionStorage.setItem("ucenter_Authorization", localStorage.getItem("ucenter_Authorization"));
            sessionStorage.setItem("loginToken", localStorage.getItem("loginToken"));
        }
      }
    }
  },
  methods: {
    mobile_bs() {
      var u = navigator.userAgent;
      return {
        trident: u.indexOf("Trident") > -1, //IE内核
        presto: u.indexOf("Presto") > -1, //opera内核
        webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
        gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
        mobile:
          !!u.match(/AppleWebKit.*Mobile.*/) ||
          (!!u.match(/AppleWebKit/) &&
            u.indexOf("QIHU") &&
            u.indexOf("QIHU") > -1 &&
            u.indexOf("Chrome") < 0), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
        iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf("iPad") > -1, //是否iPad
        webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "//at.alicdn.com/t/c/font_4280128_we4ondplfd.css";
#app {
  height: 100%;
  margin: 0;
  font-size: 12px;

  .component {
    height: 100%;
  }
}
.store-iframe-app {
  position: absolute;
  top: 0;
  width: 0;
  left: 0;
  z-index: -99999;
  display: none;
}
</style>
